/* eslint-disable */

import React, { useEffect, useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import { useNavigate } from "react-router-dom";

import { Box, Card, CircularProgress, Stack } from "@mui/material";
import { useMaterialUIController } from "context";

import configApi from "api/config";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const COLUMNS = [
  { Header: "Name", accessor: "name", align: "left" },
  { Header: "Value", accessor: "value", align: "center" },
  { Header: "Created At", accessor: "createdAt", align: "center" },
  { Header: "Updated At", accessor: "updatedAt", align: "center" },
  { Header: "Update", accessor: "update", align: "left" },
  { Header: "Delete", accessor: "delete", align: "left" },
];

const DATA = {
  name: "",
  value: "",
};

const TOAST_SETTING = {
  render: "Message Create it.",
  type: "success",
  isLoading: false,
  autoClose: 2000,
  closeButton: true,
};

function FileExtensions() {
  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const {
    register,
    formState: { errors },
    getValues,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: DATA,
  });

  const [configs, setConfigs] = useState(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const onDeleteConfigHandler = async (configId) => {
    reset({ ...DATA, configId });
    setOpenDeleteModal(true);
  };
  const onDeleteHandler = async () => {
    const { configId } = getValues();
    const id = toast.loading("Deleting Config Please Wait...");
    try {
      await configApi.delete(configId);
      await getAllConfigs();
      toast.update(id, {
        ...TOAST_SETTING,
        render: "Config Delete it Successfully.",
        type: "success",
      });
    } catch (error) {
      toast.update(id, {
        ...TOAST_SETTING,
        render: "Error in Deleting config.",
        type: "error",
      });
    }
  };

  const onCreateConfigHandler = async () => {
    reset(DATA);
    setOpenCreateModal(true);
  };
  const onCreateHandler = async () => {
    const id = toast.loading("Create Config Please Wait...");
    const value = getValues();
    try {
      await configApi.create(value);
      await getAllConfigs();
      toast.update(id, TOAST_SETTING);
    } catch (error) {
      toast.update(id, { ...TOAST_SETTING, render: "Error in createing config.", type: "error" });
    }
  };

  const onUpdateConfigHandler = async (configId, name, value) => {
    reset({ configId, name, value });
    setOpenUpdateModal(true);
  };
  const onUpdateHandler = async () => {
    const id = toast.loading("Updating Config Please Wait...");
    const value = getValues();
    try {
      await configApi.update(value.configId, { name: value.name, value: value.value });
      await getAllConfigs();
      toast.update(id, {
        ...TOAST_SETTING,
        render: "Config Update it Successfully.",
        type: "success",
      });
    } catch (error) {
      toast.update(id, {
        ...TOAST_SETTING,
        render: "Error in updating config",
        type: "error",
      });
    }
  };

  const getAllConfigs = async () => {
    try {
      const res = await configApi.getAll();

      const rows = [];
      res.forEach((config) => {
        rows.push({
          name: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {config.name}
            </MDTypography>
          ),
          value: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {config.value}
            </MDTypography>
          ),
          createdAt: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {new Date(config.createdAt).toLocaleDateString()}
            </MDTypography>
          ),
          updatedAt: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {new Date(config.updatedAt).toLocaleDateString()}
            </MDTypography>
          ),
          update: (
            <MDButton
              color="info"
              size="small"
              onClick={() => onUpdateConfigHandler(config._id, config.name, config.value)}
            >
              Edit
            </MDButton>
          ),
          delete: (
            <MDButton color="error" size="small" onClick={() => onDeleteConfigHandler(config._id)}>
              Delete
            </MDButton>
          ),
        });
      });

      setConfigs(rows);
    } catch (error) {
      navigate("/");
    }
  };

  useEffect(() => {
    getAllConfigs();
  }, []);

  return configs ? (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={3} mb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <MDTypography variant="h6" color="white">
              Config
            </MDTypography>
            <MDButton sx={{ width: 100 }} color="success" onClick={() => onCreateConfigHandler()}>
              Create
            </MDButton>
          </MDBox>
          <MDBox pt={3}>
            <DataTable
              table={{ columns: COLUMNS, rows: configs }}
              isSorted={true}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
        </Card>
      </MDBox>

      <MDDialog
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        onConfirm={onCreateHandler}
        title={"Create Config"}
        confirmTitle="Create"
      >
        <Stack spacing={2} width={300} my={1}>
          <MDInput label="Name" fullWidth register={register("name", { required: true })} />
          <MDInput label="Value" fullWidth register={register("value", { required: true })} />
        </Stack>
      </MDDialog>

      <MDDialog
        open={openUpdateModal}
        onClose={() => setOpenUpdateModal(false)}
        onConfirm={onUpdateHandler}
        title={"Update Config"}
        confirmTitle="Update"
      >
        <Stack spacing={2} width={300} my={1}>
          <MDInput label="Name" fullWidth register={register("name", { required: true })} />
          <MDInput label="Value" fullWidth register={register("value", { required: true })} />
        </Stack>
      </MDDialog>

      <MDDialog
        open={openUpdateModal}
        onClose={() => setOpenUpdateModal(false)}
        onConfirm={onUpdateHandler}
        title={"Update Config"}
        confirmTitle="Update"
      >
        <Stack spacing={2} width={300} my={1}>
          <MDInput label="Name" fullWidth register={register("name", { required: true })} />
          <MDInput label="Value" fullWidth register={register("value", { required: true })} />
        </Stack>
      </MDDialog>

      <MDDialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={onDeleteHandler}
        title={"Delete Config"}
        confirmTitle="Delete"
      >
        <MDTypography>Are you sure you want to delete Config</MDTypography>
      </MDDialog>

      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default FileExtensions;
