/* eslint-disable */

import React, { useEffect, useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import { useNavigate } from "react-router-dom";

import { Box, Card, CircularProgress, Stack } from "@mui/material";
import { useMaterialUIController } from "context";

import domainApi from "api/domain";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";

const COLUMNS = [
  { Header: "Name", accessor: "name", align: "left" },
  { Header: "Owner Name", accessor: "ownerName", align: "center" },
  { Header: "Owner Email", accessor: "ownerEmail", align: "center" },
  { Header: "Created At", accessor: "createdAt", align: "center" },
  { Header: "Updated At", accessor: "updatedAt", align: "center" },
  { Header: "View", accessor: "view", align: "left" },
];

function Domains() {
  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [domains, setDomains] = useState(null);
  const [domainsRow, setDomainsRow] = useState(null);

  const getAllDomains = async () => {
    try {
      const res = await domainApi.getAllDomains();

      const rows = [];
      res.forEach((domain) => {
        rows.push({
          name: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {domain.domain}
            </MDTypography>
          ),
          ownerName: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {domain.ownerId.name}
            </MDTypography>
          ),
          ownerEmail: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {domain.ownerId.email}
            </MDTypography>
          ),
          createdAt: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {new Date(domain.createdAt).toLocaleDateString()}
            </MDTypography>
          ),
          updatedAt: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {new Date(domain.updatedAt).toLocaleDateString()}
            </MDTypography>
          ),
          view: (
            <MDButton
              color="success"
              size="small"
              onClick={() => navigate(`/domain/${domain.domain}`)}
            >
              Edit
            </MDButton>
          ),
        });
      });

      setDomainsRow(rows);
      setDomains(res);
    } catch (error) {}
  };

  useEffect(() => {
    getAllDomains();
  }, []);

  return domains ? (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={3} mb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <MDTypography variant="h6" color="white">
              Domains
            </MDTypography>
          </MDBox>
          <MDBox pt={3}>
            <DataTable
              table={{ columns: COLUMNS, rows: domainsRow }}
              isSorted={true}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
        </Card>
      </MDBox>

      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default Domains;
