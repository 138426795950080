/* eslint-disable */

import React, { useEffect, useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import { Link, useNavigate, useParams } from "react-router-dom";

import { Box, Card, CircularProgress, Stack, Switch } from "@mui/material";
import { useMaterialUIController } from "context";

import domainApi from "api/domain";
import subscriptionApi from "api/subscription";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

import moment from "moment";
import handleError from "utils/handleError";
import { toast } from "react-toastify";

function DomainSubscription() {
  const navigate = useNavigate();
  const { domainName } = useParams();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [domainSubscription, setDomainSubscription] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(null);

  const getDomainSubscription = async () => {
    try {
      const res = await subscriptionApi.getByDomainName(domainName);
      setDomainSubscription(res);
    } catch (error) {
      navigate("/domain");
    }
  };

  const onDeleteSubscription = async (subscriptionId) => {
    setLoadingDelete(true);
    try {
      const res = await subscriptionApi.delete(subscriptionId);
      getDomainSubscription();
    } catch (error) {
      toast.error(handleError(error));
    } finally {
      setLoadingDelete(false);
    }
  };

  useEffect(() => {
    getDomainSubscription();
  }, []);

  return domainSubscription ? (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox sx={{ display: "flex", justifyContent: "flex-end" }}>
        <MDButton color="success" onClick={() => navigate(`create`)} sx={{ marginY: 4 }}>
          Create new Subscription
        </MDButton>
      </MDBox>
      <Stack spacing={2}>
        {domainSubscription.map((e, i) => (
          <Card sx={{ padding: 3 }} key={i}>
            <MDTypography>Description: {e.description}</MDTypography>
            <MDTypography>
              Amount: {e.amount} {e.currency}
            </MDTypography>
            <MDTypography>Payment Type: {e.paymentType}</MDTypography>
            <MDTypography>
              End Subscription: {moment(e.endSubscription).format("DD-MM-YYYY")}
            </MDTypography>
            <MDTypography>Created At: {moment(e.createdAt).format("DD-MM-YYYY")}</MDTypography>
            <Stack direction="row" spacing={2}>
              <MDButton color="error" size="small" onClick={() => onDeleteSubscription(e._id)}>
                Delete
                {loadingDelete && (
                  <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />
                )}
              </MDButton>
              <MDButton color="info" size="small" onClick={() => navigate(e._id)}>
                Update
              </MDButton>
            </Stack>
          </Card>
        ))}
      </Stack>
      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default DomainSubscription;
