/* eslint-disable */

import React, { useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import { useNavigate } from "react-router-dom";

import { Box, Card, CircularProgress, Stack } from "@mui/material";
import { useMaterialUIController } from "context";

import fileExtensionApi from "api/file-extension";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { toast } from "react-toastify";
import MDBox from "components/MDBox";
import _ from "lodash";

function CreateFileExtension() {
  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [fileExt, setFileExt] = useState({
    name: "",
    ext: [""],
    htmlFile: [""],
  });
  const [loading, setLoading] = useState(false);

  const onChangeHandler = (event) => {
    const newData = _.update(fileExt, event.target.name, (data) => {
      return event.target.value;
    });
    setFileExt({ ...newData });
  };

  const removeFromArray = (indexElement, isExt = false) => {
    setFileExt((current) => ({
      ...current,
      ext: isExt ? current.ext.filter((_, i) => i !== indexElement) : [...current.ext],
      htmlFile: !isExt
        ? current.htmlFile.filter((_, i) => i !== indexElement)
        : [...current.htmlFile],
    }));
  };

  const addToArray = (isExt = false) => {
    setFileExt((current) => ({
      ...current,
      ext: isExt ? [...current.ext, ""] : [...current.ext],
      htmlFile: !isExt ? [...current.htmlFile, ""] : [...current.htmlFile],
    }));
  };

  const createFileExtension = async () => {
    setLoading(true);
    try {
      const res = await fileExtensionApi.createExt({
        name: fileExt.name,
        ext: fileExt.ext,
        htmlFile: fileExt.htmlFile,
      });
      navigate(`/file-extension/${res._id}`);
      toast.success(`File Extension created successfully`);
    } catch (error) {
      toast.error(`error: ${error.response.data.error}`);
    } finally {
      setLoading(false);
    }
  };

  return fileExt ? (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={3} mb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <MDTypography variant="h6" color="white">
              Create File Extension
            </MDTypography>
          </MDBox>
          <MDBox pt={3}>
            <Stack spacing={2} paddingX={5}>
              <MDInput
                value={fileExt.name}
                label={"Name"}
                name="name"
                fullWidth
                onChange={onChangeHandler}
              />
              <MDTypography variant="h6">Extension</MDTypography>
              <Stack spacing={2} paddingX={2}>
                {fileExt.ext.map((e, i) => (
                  <Stack direction="row" spacing={2} key={i}>
                    <MDInput
                      value={e}
                      label={`Ext.${i}`}
                      name={`ext.${i}`}
                      fullWidth
                      onChange={onChangeHandler}
                    />
                    <MDButton color="error" onClick={() => removeFromArray(i, true)}>
                      Remove
                    </MDButton>
                  </Stack>
                ))}
                <MDButton sx={{ width: 100 }} color="success" onClick={() => addToArray(true)}>
                  Add
                </MDButton>
              </Stack>
              <MDTypography variant="h6">HTML File</MDTypography>
              <Stack spacing={2} paddingX={2}>
                {fileExt.htmlFile.map((e, i) => (
                  <Stack direction="row" spacing={2} key={i}>
                    <MDInput
                      value={e}
                      label={`htmlFile.${i}`}
                      name={`htmlFile.${i}`}
                      fullWidth
                      onChange={onChangeHandler}
                    />
                    <MDButton color="error" onClick={() => removeFromArray(i)}>
                      Remove
                    </MDButton>
                  </Stack>
                ))}
                <MDButton sx={{ width: 100 }} color="success" onClick={() => addToArray()}>
                  Add
                </MDButton>
              </Stack>
            </Stack>
            <MDBox sx={{ marginX: 3 }}>
              <MDButton
                fullWidth
                sx={{ marginY: 3 }}
                color="info"
                onClick={() => createFileExtension()}
              >
                Save
                {loading && (
                  <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />
                )}
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>

      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default CreateFileExtension;
