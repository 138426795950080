import AxiosInstance from "./AxiosInstance";

const setting = {
  getById: async (settingId) => {
    const res = await AxiosInstance.get(`/setting/${settingId}`);
    return res.data;
  },
  getByDomainId: async (domainId) => {
    const res = await AxiosInstance.get(`/setting/domain/${domainId}`);
    return res.data;
  },
  update: async (settingId, body) => {
    const res = await AxiosInstance.put(`/setting/${settingId}`, body);
    return res.data;
  },
};

export default setting;
