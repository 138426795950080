/* eslint-disable */

import React, { useEffect, useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import { useNavigate, useParams } from "react-router-dom";

import { Box, Card, CircularProgress, Stack, Switch } from "@mui/material";
import { useMaterialUIController } from "context";

import pageApi from "api/page";
import domainApi from "api/domain";

import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { toast } from "react-toastify";

import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import { blogRefExample, pageRefExample } from "utils/refSetting";

function CreatePage() {
  const navigate = useNavigate();
  const { domainName } = useParams();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [domainInfo, setDomainInfo] = useState(null);
  const [pageInfo, setPageInfo] = useState({
    page_route: "/",
    page_data: {},
  });
  const [loading, setLoading] = useState(null);
  const [confirmGood, setConfirmGood] = useState(false);

  const onChangeHandler = (e) => {
    if (e.error !== false) {
      setConfirmGood(true);
    } else {
      setConfirmGood(false);
      setPageInfo((old) => ({ ...old, page_data: e.jsObject }));
    }
  };

  const createPage = async () => {
    setLoading(true);

    if (!pageInfo.page_route.includes("/")) {
      toast.error(`Page route should have /`);
      return;
    }

    try {
      const res = await pageApi.create({ ...pageInfo, domainId: domainInfo._id });
      toast.success(`Page created successfully`);
      navigate(`/domain/${domainName}/page/${res._id}`);
    } catch (error) {
      toast.error(`${error.response.data.message || error.response.data.error}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    domainApi.getDomainInfoByDomainName(domainName, (res) => setDomainInfo(res));
  }, []);

  return pageInfo && domainInfo ? (
    <DashboardLayout>
      <DashboardNavbar />

      <Stack direction="row" width="100%" mb={4}>
        <Stack sx={{ flex: 1 }} direction="column">
          <MDTypography>Blog Ref Example</MDTypography>
          <JSONInput placeholder={blogRefExample} viewOnly={true} height="100%" width="99%" />
        </Stack>
        <Stack sx={{ flex: 1 }} direction="column">
          <MDTypography>Page Ref Example</MDTypography>
          <JSONInput placeholder={pageRefExample} viewOnly={true} height="100%" width="99%" />
        </Stack>
      </Stack>

      <Card sx={{ padding: 5, marginBottom: 10 }}>
        <MDInput
          placeholder="Page Route"
          fullWidth
          value={pageInfo.page_route}
          onChange={(e) => setPageInfo((old) => ({ ...old, page_route: e.target.value }))}
        />

        <JSONInput
          id={"1"}
          placeholder={pageInfo.page_data}
          // colors={darktheme}
          locale={locale}
          height="100%"
          width="100%"
          onChange={(e) => onChangeHandler(e)}
        />

        <MDButton
          color="success"
          disabled={confirmGood}
          onClick={() => createPage()}
          sx={{ marginTop: 5 }}
        >
          Create
          {loading && <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />}
        </MDButton>
      </Card>

      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default CreatePage;
