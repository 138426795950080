/* eslint-disable */

import axios from "axios";
import { useOwnerStore } from "../store/ownerStore";
// import { getItem } from "../utils/session";

const checkDomain = () => {
  if (process.env.REACT_APP_ENV) {
    const env = process.env.REACT_APP_ENV.toString().trim();
    if (env === "local") return process.env.REACT_APP_API_KEY_LOCAL;
    else if (env === "qa") return process.env.REACT_APP_API_KEY_QA;
    else return process.env.REACT_APP_API_KEY_PROD;
  } else {
    return process.env.REACT_APP_API_KEY_PROD;
  }
};

const AxiosInstance = axios.create({
  baseURL: checkDomain() + "/api/v1/",
  // baseURL: "http://127.0.0.1:3333/api/v1/",
  // withCredentials: true,
});

export default AxiosInstance;

AxiosInstance.interceptors.request.use(function (config) {
  const token = useOwnerStore.getState().ownerStore;
  // const token = getItem('ownerToken');
  if (token) config.headers.Authorization = `Bearer ${token}`;

  return config;
});

AxiosInstance.interceptors.response.use(
  function (response) {
    return Promise.resolve(response);
  },
  function (error) {
    // if (error.response.data.error === 'Invalid Token') {
    // window.location.pathname("http://stackoverflow.com");
    // }
    return Promise.reject(error);
  }
);
