/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { useOwnerStore } from "store";

import employeeApi from "api/employee";
import configApi from "api/config";
import Owner from "layouts/owner";
import CreateOwner from "layouts/create-owner";
import Domain from "layouts/domain";
import CreateDomain from "layouts/create-domain";
import Setting from "layouts/setting";
import Page from "layouts/page";
import PageInfo from "layouts/page/page-info";
import CreatePage from "layouts/page/create-page";
import FileExtension from "layouts/file-extension";
import CreateFileExtension from "layouts/create-file-extension";
import DomainInfo from "layouts/domain-info";
import DomainSubscription from "layouts/subscription";
import CreateDomainSubscription from "layouts/subscription/create";
import { LocalizationProvider } from "@mui/x-date-pickers";
import UpdateDomainSubscription from "layouts/subscription/update";

import ErrorFallback from "layouts/error";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  const ownerStore = useOwnerStore((state) => state.ownerStore);
  const ownerData = useOwnerStore((state) => state.ownerData);
  const setOwnerData = useOwnerStore((state) => state.setOwnerData);

  const getMyInfo = async () => {
    try {
      const res = await employeeApi.getEmployeeByToken();
      setOwnerData(res);
    } catch (error) {
      navigate("/authentication/sign-in");
    }
  };

  const getConfigName = async () => {
    const releaseAdmin = "releaseAdmin";
    const value = await configApi.getByName(releaseAdmin);
    const oldValue = localStorage.getItem(releaseAdmin);
    localStorage.setItem(releaseAdmin, value.value);
    if (oldValue !== value.value) window.location.reload(true);
  };

  useEffect(() => {
    getMyInfo();
    // eslint-disable-next-line
  }, [ownerStore]);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    getConfigName();
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ToastContainer
          bodyStyle={{
            fontSize: 13,
          }}
        />
        <CssBaseline />
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {layout === "dashboard" && ownerData && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName="Material Dashboard 2"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
            </>
          )}
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/dashboard" />} />
            <Route path="/owner/create" element={<CreateOwner />} />
            <Route path="/owner/:ownerId" element={<Owner />} />
            <Route path="/domain/create/:ownerId" element={<CreateDomain />} />
            <Route path="/domain/:domainName/page/create" element={<CreatePage />} />
            <Route path="/domain/:domainName/page/:pageId" element={<PageInfo />} />
            <Route path="/domain/:domainName/page" element={<Page />} />
            <Route path="/domain/:domainName/setting" element={<Setting />} />
            <Route path="/domain/:domainName/update" element={<Domain />} />
            <Route path="/domain/:domainName/subscription" element={<DomainSubscription />} />
            <Route
              path="/domain/:domainName/subscription/create"
              element={<CreateDomainSubscription />}
            />
            <Route
              path="/domain/:domainName/subscription/:subscriptionId"
              element={<UpdateDomainSubscription />}
            />
            <Route path="/domain/:domainName" element={<DomainInfo />} />
            <Route path="/file-extension/create" element={<CreateFileExtension />} />
            <Route path="/file-extension/:fileExtensionId" element={<FileExtension />} />
          </Routes>
        </ErrorBoundary>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
