/* eslint-disable */

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import MDInput from "components/MDInput";

import ownerApi from "api/owner";
import employeeApi from "api/employee";
import notificationApi from "api/notification";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CircularProgress, Modal, Stack } from "@mui/material";
import ConfirmDialog from "components/ConfirmDialog";

function ProfileInfoCard({ title, description, info, action, shadow, isEditMode, employeeId }) {
  const { ownerId } = useParams();
  const navigate = useNavigate();

  const labels = [];
  const values = [];
  const { socialMediaColors } = colors;
  const { size } = typography;

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  const {
    register,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const [loadingData, setLoadingData] = useState(false);
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingPassManual, setLoadingPassManual] = useState(false);
  const [isUpdatingPassManual, setIsUpdatingPassManual] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [loadingNotification, setLoadingNotification] = useState(false);

  const openDialogHandler = () => setOpenDialog(true);
  const closeDialogHandler = () => setOpenDialog(false);

  const [passwordManual, setPasswordManual] = useState({
    password: "",
    confirm_password: "",
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  const onSubmit = async () => {
    setLoadingData(true);
    const value = getValues();
    try {
      const res = await ownerApi.updateClientInfo(ownerId, value);
      action.getOwner();
      action.onclick();
      toast.success("update successfully");
    } catch (error) {
      toast.error(`error: ${error.response.data.error}`);
    } finally {
      setLoadingData(false);
    }
  };

  const updatePassword = async () => {
    setLoadingPassword(true);
    try {
      const res = await ownerApi.updateClientPassword(ownerId);
      toast.success("update password successfully");
    } catch (error) {
      toast.error(`error: ${error.response.data.error}`);
    } finally {
      setLoadingPassword(false);
    }
  };

  const updatePasswordManual = async () => {
    if (
      passwordManual.confirm_password !== passwordManual.password ||
      !passwordManual.password ||
      !passwordManual.confirm_password
    )
      toast.error(`Password not valid`);

    setLoadingPassManual(true);
    try {
      const res = await ownerApi.updateClientPasswordManual(ownerId, passwordManual);
      toast.success("update password successfully");
      updatePasswordManualHandler();
    } catch (error) {
      toast.error(`error: ${error.response.data.error}`);
    } finally {
      setLoadingPassManual(false);
    }
  };

  const deleteOwner = async () => {
    setLoadingDelete(true);
    try {
      const res = await ownerApi.delete(ownerId);
      toast.success("Domain Delete Successfully");
      navigate(`/owner`);
    } catch (error) {
      toast.error(`error: ${error.response.data.error}`);
    } finally {
      setLoadingDelete(false);
    }
  };

  const onSendNotification = async () => {
    setLoadingNotification(true);
    try {
      const res = await notificationApi.sendNotificationToClient(ownerId, {
        title: notification.title,
        body: notification.body,
      });
      toast.success("notification send it successfully");
      setNotification({ title: "", body: "" });
    } catch (error) {
      toast.error(`error: ${error.response.data.error}`);
    } finally {
      setLoadingNotification(false);
    }
  };

  const updatePasswordManualHandler = () => setIsUpdatingPassManual((old) => !old);

  const onChangePassword = (e) => {
    setPasswordManual((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display="flex" py={1} pr={2}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
            {label}: &nbsp;
          </MDTypography>
          {!isEditMode && (
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{values[key]}
            </MDTypography>
          )}
        </Grid>
        {isEditMode && (
          <Grid item xs={6} md={10} xl={10} sx={{ display: "flex" }}>
            <MDInput
              type="email"
              label={label}
              fullWidth
              register={register(label, { required: true })}
            />
          </Grid>
        )}
      </Grid>
    </MDBox>
  ));

  useEffect(() => {
    labels.forEach((label, key) => {
      setValue(label, values[key]);
    });
  }, []);

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none", marginBottom: 10 }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
        <MDTypography variant="body2" color="secondary">
          <Tooltip
            title={(isEditMode ? "Close " : "Edit ") + action.tooltip}
            placement="top"
            onClick={() => action.onclick()}
          >
            <Icon>{isEditMode ? "close" : "edit"}</Icon>
          </Tooltip>
        </MDTypography>
      </MDBox>

      <MDBox p={2}>
        <MDBox mb={2} lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="light">
            {description}
          </MDTypography>
        </MDBox>
        <MDBox opacity={0.3}>
          <Divider />
        </MDBox>
        <MDBox>
          {renderItems}
          <Divider />

          <Stack spacing={2}>
            <MDInput
              label="Title"
              fullWidth
              value={notification.title}
              onChange={(e) => setNotification((old) => ({ ...old, title: e.target.value }))}
            />
            <MDInput
              label="Body"
              fullWidth
              value={notification.body}
              onChange={(e) => setNotification((old) => ({ ...old, body: e.target.value }))}
            />
            <MDButton
              variant="gradient"
              color="info"
              disabled={!notification.title || !notification.body}
              onClick={onSendNotification}
            >
              Send Notification
              {loadingNotification && (
                <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />
              )}
            </MDButton>
          </Stack>

          <Divider />

          <MDButton variant="gradient" color="error" onClick={openDialogHandler}>
            Delete Owner
            {loadingDelete && (
              <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />
            )}
          </MDButton>
          <Divider />

          {isUpdatingPassManual && (
            <Stack spacing={2}>
              <MDInput
                label={"Password"}
                type="password"
                name="password"
                fullWidth
                onChange={onChangePassword}
              />
              <MDInput
                label={"Confirm Password"}
                type="password"
                name="confirm_password"
                fullWidth
                onChange={onChangePassword}
              />
              <MDButton variant="gradient" color="success" onClick={updatePasswordManual}>
                Update
                {loadingPassManual && (
                  <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />
                )}
              </MDButton>
              <MDButton variant="gradient" color="error" onClick={updatePasswordManualHandler}>
                Cancel
              </MDButton>
            </Stack>
          )}

          {!isEditMode && !isUpdatingPassManual && (
            <Stack direction="row" spacing={2} mt={2}>
              <MDButton variant="gradient" color="success" onClick={updatePasswordManualHandler}>
                Change Password Manual
              </MDButton>

              <MDButton variant="gradient" color="success" onClick={updatePassword}>
                Change Password By Email
                {loadingPassword && (
                  <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />
                )}
              </MDButton>
            </Stack>
          )}

          {isEditMode && (
            <MDBox mt={2} mb={1} alignItems="center" alignSelf="center" onClick={onSubmit}>
              <MDButton variant="gradient" color="success">
                Update
                {loadingData && (
                  <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />
                )}
              </MDButton>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
      <ConfirmDialog
        open={openDialog}
        onClose={closeDialogHandler}
        onConfirm={deleteOwner}
        title={"Delete Domain"}
        content={"Are you sure you want to delete domain"}
        confirmTitle="Delete"
      />
    </Card>
  );
}

// Setting default props for the ProfileInfoCard
ProfileInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  action: PropTypes.shape({
    onclick: PropTypes.func.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
  isEditMode: PropTypes.bool.isRequired,
};

export default ProfileInfoCard;
