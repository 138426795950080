/* eslint-disable */

import React, { useEffect, useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import { useNavigate } from "react-router-dom";

import { Box, Card, CircularProgress } from "@mui/material";
import { useMaterialUIController } from "context";

import fileExtensionApi from "api/file-extension";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";

const COLUMNS = [
  { Header: "Name", accessor: "name", align: "left" },
  { Header: "Extension", accessor: "ext", align: "center" },
  { Header: "Created At", accessor: "createdAt", align: "center" },
  { Header: "Updated At", accessor: "updatedAt", align: "center" },
  { Header: "View", accessor: "view", align: "left" },
];

function FileExtensions() {
  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [fileExt, setFileExt] = useState(null);

  const getAllExtension = async () => {
    try {
      const res = await fileExtensionApi.getAllExt();

      const rows = [];
      res.forEach((ext) => {
        rows.push({
          name: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {ext.name}
            </MDTypography>
          ),
          ext: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {ext.ext.join()}
            </MDTypography>
          ),
          createdAt: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {new Date(ext.createdAt).toLocaleDateString()}
            </MDTypography>
          ),
          updatedAt: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {new Date(ext.updatedAt).toLocaleDateString()}
            </MDTypography>
          ),
          view: (
            <MDButton
              color="success"
              size="small"
              onClick={() => navigate(`/file-extension/${ext._id}`)}
            >
              Edit
            </MDButton>
          ),
        });
      });

      setFileExt(rows);
    } catch (error) {
      navigate("/");
    }
  };

  const navigateToCreate = () => {
    navigate(`/file-extension/create`);
  };

  useEffect(() => {
    getAllExtension();
  }, []);

  return fileExt ? (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={3} mb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <MDTypography variant="h6" color="white">
              File Extensions
            </MDTypography>
            <MDButton sx={{ width: 100 }} color="success" onClick={() => navigateToCreate()}>
              Create
            </MDButton>
          </MDBox>
          <MDBox pt={3}>
            <DataTable
              table={{ columns: COLUMNS, rows: fileExt }}
              isSorted={true}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
        </Card>
      </MDBox>

      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default FileExtensions;
