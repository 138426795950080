const blogRefExample = {
  _ref: {
    "_modal*": "blog",
    _selectedItem: [
      "image",
      "slug",
      "title",
      "description",
      "content",
      "meta",
      "createdAt",
      "updatedAt",
    ],
    _id: `[] or empty String`,
    _sortDate: `createdAt, -createdAt, updatedAt, -updatedAt`,
    _perPage: 10,
  },
  ex: {
    _sortDate: "Default is -createdAt",
  },
};

const pageRefExample = {
  _ref: {
    "_modal*": "page",
    "_pageRoute*": "/our-works/",
    _sortDate: `createdAt, -createdAt, updatedAt, -updatedAt`,
    _selectedItem: [],
    _id: `[] or empty String`,
    _perPage: 10,
    _filter: [
      {
        _filterType: "string, number, boolean",
        _filterItem: "a.b",
        "_filterBy*string": "startsWith, endsWith, existsIn",
        "_filterBy*number":
          "graterThan, graterThanOrEqual, lessThan, lessThanOrEqual, equal, notEqual",
        "_filterBy*boolean": "true-false, 1-0",
        _filterParamsAs: "",
        _filterDefaultValue: "",
      },
    ],
    _sort: [
      {
        _sortType: "string, number, boolean",
        _sortItem: "a.c",
        _sortBy: "DESC-ASC",
        _sortParamsAs: "",
        _sortDefaultValue: "",
      },
    ],
  },
  ex: {
    _sortDate: "Default is -createdAt",
  },
};

module.exports = { blogRefExample, pageRefExample };
