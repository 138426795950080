/* eslint-disable */

import React, { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Dashboard components
import { useNavigate, useParams } from "react-router-dom";
import { useOwnerStore, useDomainStore } from "store";

import { Box, Card, CircularProgress, Stack } from "@mui/material";
import { useMaterialUIController } from "context";

import ownerApi from "api/owner";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import MDInput from "components/MDInput";
import { useForm } from "react-hook-form";

function CreateOwner() {
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    getValues,
  } = useForm();

  const [loading, setLoading] = useState(false);

  const createOwner = async () => {
    const value = getValues();
    setLoading(true);
    try {
      const res = await ownerApi.createClient(value);
      navigate(`/owner/${res._id}`);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card sx={{ padding: 5, marginBottom: 10 }}>
        <Stack spacing={4}>
          <MDInput label="Name" fullWidth register={register("name", { required: true })} />
          <MDInput
            type="email"
            label="Email"
            fullWidth
            register={register("email", { required: true })}
          />
          <MDButton color="success" onClick={createOwner}>
            Create
            {loading && <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />}
          </MDButton>
        </Stack>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default CreateOwner;
