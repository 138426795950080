import AxiosInstance from "./AxiosInstance";

const auth = {
  getAll: async () => {
    const res = await AxiosInstance.get(`config`);
    return res.data;
  },
  getById: async (configId) => {
    const res = await AxiosInstance.get(`config/${configId}`);
    return res.data;
  },
  getByName: async (name) => {
    const res = await AxiosInstance.get(`config/name?name=${name}`);
    return res.data;
  },
  create: async (data) => {
    const res = await AxiosInstance.post(`config`, data);
    return res.data;
  },
  update: async (configId, data) => {
    const res = await AxiosInstance.put(`config/${configId}`, data);
    return res.data;
  },
  delete: async (configId) => {
    const res = await AxiosInstance.delete(`config/${configId}`);
    return res.data;
  },
};

export default auth;
