/* eslint-disable */

import React, { useEffect, useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import { useNavigate, useParams } from "react-router-dom";

import { Card, CircularProgress, Stack, Switch } from "@mui/material";

import domainApi from "api/domain";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

function CreateDomain() {
  const navigate = useNavigate();
  const { ownerId } = useParams();

  const {
    register,
    formState: { errors },
    getValues,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      subDomains: [],
      limitStorage: 50,
    },
  });

  const isMETAActivate = watch("isMETAActivate");
  const subDomains = watch("subDomains");

  const [loading, setLoading] = useState(false);

  const createDomain = async () => {
    const value = getValues();
    setLoading(true);
    try {
      const res = await domainApi.createDomain(ownerId, value);
      toast.success(`Domain created it successfully`);
      navigate(`/domain/${res._id}`);
    } catch (error) {
      toast.error(`error: ${error.response.data.error}`);
    } finally {
      setLoading(false);
    }
  };

  const addNewSubDomains = () => {
    setValue("subDomains", [...subDomains, ""]);
  };
  const removeNewSubDomains = (indexElement) => {
    setValue(
      "subDomains",
      subDomains.filter((_, i) => i !== indexElement)
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card sx={{ paddingX: 5, paddingY: 2, marginBottom: 10 }}>
        <Stack spacing={4}>
          <MDInput
            label="domain name"
            fullWidth
            register={register("domain", { required: true })}
          />

          <MDTypography variant="h6">Sub Domains</MDTypography>
          <Stack spacing={2} paddingX={2}>
            {(subDomains || []).map((e, i) => (
              <Stack direction="row" spacing={2} key={i}>
                <MDInput
                  label={`subDomains.${i}`}
                  x
                  fullWidth
                  register={register(`subDomains.${i}`, { required: true })}
                />
                <MDButton color="error" onClick={() => removeNewSubDomains(i)}>
                  Remove
                </MDButton>
              </Stack>
            ))}
            <MDButton sx={{ width: 100 }} color="success" onClick={() => addNewSubDomains()}>
              Add
            </MDButton>
          </Stack>

          <MDInput
            label="Domain Limit Storage"
            fullWidth
            register={register("limitStorage", { required: true })}
          />

          <Stack>
            <MDTypography>Activate Blog Service</MDTypography>
            <Switch defaultValue={false} {...register("isBlogActivate")} />
          </Stack>

          <Stack>
            <MDTypography>Is Developer Service</MDTypography>
            <Switch defaultValue={false} {...register("isDeveloper")} />
          </Stack>

          <Stack>
            <MDTypography>Activate META Service</MDTypography>
            <Switch defaultValue={false} {...register("isMETAActivate")} />
          </Stack>

          <Stack>
            <MDTypography>Unlimited Requests</MDTypography>
            <Switch defaultValue={false} {...register("isUnlimitedRequest")} />
          </Stack>

          {isMETAActivate && (
            <Stack spacing={2}>
              <MDTypography>META Services</MDTypography>
              <MDInput
                label="Title"
                fullWidth
                register={register("meta.title", { required: true })}
              />
              <MDInput
                label="Description"
                fullWidth
                register={register("meta.description", { required: true })}
              />
              <MDInput
                label="Type"
                fullWidth
                register={register("meta.type", { required: true })}
              />
              <MDInput label="Url" fullWidth register={register("meta.url", { required: true })} />
            </Stack>
          )}

          <MDButton color="success" onClick={createDomain}>
            Create
            {loading && <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />}
          </MDButton>
        </Stack>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default CreateDomain;
