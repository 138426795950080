/* eslint-disable */

import React, { useEffect, useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import { useNavigate, useParams } from "react-router-dom";

import { Box, Card, CircularProgress, Stack, Switch } from "@mui/material";
import { useMaterialUIController } from "context";

import domainApi from "api/domain";
import mediaApi from "api/media";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { toast } from "react-toastify";
import MDFile from "components/MDFile";
import { useForm } from "react-hook-form";

function Domain() {
  const navigate = useNavigate();
  const { domainName } = useParams();

  const { register, getValues, setValue, watch } = useForm();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const isBlogActivate = watch("isBlogActivate");
  const isMETAActivate = watch("isMETAActivate");
  const isDeveloper = watch("isDeveloper");
  const metaImage = watch("meta.image");
  const metaIcon = watch("meta.icon");
  const subDomains = watch("subDomains");
  const isUnlimitedRequest = watch("isUnlimitedRequest");

  const [domain, setDomain] = useState(null);
  const [domainId, setDomainId] = useState(null);
  const [loading, setLoading] = useState(null);

  const getDomain = async () => {
    try {
      const res = await domainApi.getDomainInfoByDomainName(domainName, setDomain);
      Object.keys(res).forEach((key) => {
        setValue(key, res[key]);
      });
      setDomainId(res._id);
    } catch (error) {
      navigate("/domain");
    }
  };

  const uploadImageHandler = async (domainId, path, data) => {
    try {
      const formData = new FormData();
      formData.append("image", data);
      const res = await mediaApi.updateDomainMeta(domainId, path, formData);
      getDomain();
      toast.success(`Image updated successfully`);
      return true;
    } catch (error) {
      toast.error(`error: ${error.response.data.error}`);
      return false;
    }
  };

  const updateDomain = async () => {
    const values = getValues();
    let body = {
      domain: values.domain,
      subDomains: values.subDomains,
      isBlogActivate: values.isBlogActivate,
      isMETAActivate: values.isMETAActivate,
      isDeveloper: values.isDeveloper,
      limitStorage: values.limitStorage,
      isUnlimitedRequest: values.isUnlimitedRequest,
    };

    if (values.isMETAActivate) {
      body = { ...body, meta: { ...values.meta, image: undefined, icon: undefined } };
    }

    setLoading(true);
    try {
      const res = await domainApi.updateDomain(domainId, body);
      setDomain(res);
      toast.success(`Domain updated successfully`);
    } catch (error) {
      toast.error(`error: ${error.response.data.error}`);
    } finally {
      setLoading(false);
    }
  };

  const addNewSubDomains = () => {
    setValue("subDomains", [...subDomains, ""]);
  };
  const removeNewSubDomains = (indexElement) => {
    setValue(
      "subDomains",
      subDomains.filter((_, i) => i !== indexElement)
    );
  };

  useEffect(() => {
    getDomain();
  }, []);

  return domain && domainId ? (
    <DashboardLayout>
      <DashboardNavbar />

      <Card sx={{ padding: 5, marginBottom: 10 }}>
        <Stack spacing={4}>
          <MDInput
            label="domain name"
            fullWidth
            register={register("domain", { required: true })}
          />

          <MDTypography variant="h6">Sub Domains</MDTypography>
          <Stack spacing={2} paddingX={2}>
            {(subDomains || []).map((e, i) => (
              <Stack direction="row" spacing={2} key={i}>
                <MDInput
                  label={`subDomains.${i}`}
                  fullWidth
                  register={register(`subDomains.${i}`, { required: true })}
                />
                <MDButton color="error" onClick={() => removeNewSubDomains(i)}>
                  Remove
                </MDButton>
              </Stack>
            ))}
            <MDButton sx={{ width: 100 }} color="success" onClick={() => addNewSubDomains()}>
              Add
            </MDButton>
          </Stack>

          <MDInput
            label="Domain Limit Storage"
            fullWidth
            register={register("limitStorage", { required: true })}
          />

          <Stack>
            <MDTypography>Activate Blog Service</MDTypography>
            <Switch checked={isBlogActivate} {...register("isBlogActivate")} />
          </Stack>

          <Stack>
            <MDTypography>Is Developer Service</MDTypography>
            <Switch checked={isDeveloper} {...register("isDeveloper")} />
          </Stack>

          <Stack>
            <MDTypography>Activate META Service</MDTypography>
            <Switch checked={isMETAActivate} {...register("isMETAActivate")} />
          </Stack>

          <Stack>
            <MDTypography>Unlimited Requests</MDTypography>
            <Switch checked={isUnlimitedRequest} {...register("isUnlimitedRequest")} />
          </Stack>

          {isMETAActivate && (
            <Stack spacing={2}>
              <MDTypography>META Services</MDTypography>
              <MDInput
                label="Title"
                fullWidth
                register={register("meta.title", { required: true })}
              />
              <MDInput
                label="Description"
                fullWidth
                register={register("meta.description", { required: true })}
              />
              <MDInput
                label="Type"
                fullWidth
                register={register("meta.type", { required: true })}
              />
              <MDInput label="Url" fullWidth register={register("meta.url", { required: true })} />
              <MDFile
                title={"Image"}
                value={metaImage}
                domainId={domainId}
                path={"meta.image"}
                onClickHandler={uploadImageHandler}
              />
              <MDFile
                title={"Icon"}
                value={metaIcon}
                domainId={domainId}
                path={"meta.icon"}
                onClickHandler={uploadImageHandler}
              />
            </Stack>
          )}

          <MDButton color="success" onClick={updateDomain}>
            Update
            {loading && <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />}
          </MDButton>
        </Stack>
      </Card>
      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default Domain;
