/* eslint-disable */

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Box, CircularProgress, Modal, Stack } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

// Overview page components
import Header from "layouts/profile/components/Header";

import { useOwnerStore } from "store";
import { useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import MDInput from "components/MDInput";
import { useForm } from "react-hook-form";
import MDButton from "components/MDButton";
import employee from "api/employee";
import { toast } from "react-toastify";

function Profile() {
  const ownerData = useOwnerStore((state) => state.ownerData);

  const {
    register,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [loading, setLoading] = useState(false);
  const [loadingPassword, setLoadingPassword] = useState(false);

  const onUpdateInfo = async () => {
    setLoading(true);
    const values = getValues();
    const body = {
      name: values.name,
      email: values.email,
    };
    try {
      const res = await employee.update(ownerData._id, body);
      toast.success("Data has been update it successfully");
    } catch (error) {
      toast.error(`Error: ${error.response.data.error}`);
    } finally {
      setLoading(false);
    }
  };

  const onUpdatePassword = async () => {
    setLoadingPassword(true);
    const values = getValues();
    const body = {
      oldPassword: values.oldPassword,
      password: values.password,
      confirmPassword: values.confirmPassword,
    };
    try {
      const res = await employee.updatePassword(body);
      toast.success("Data has been update it successfully");
    } catch (error) {
      toast.error(`Error: ${error.response.data.error}`);
    } finally {
      setLoadingPassword(false);
    }
  };

  useEffect(() => {
    if (!ownerData) return;
    setValue("name", ownerData.name);
    setValue("email", ownerData.email);
  }, [ownerData]);

  return ownerData ? (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Stack spacing={3}>
            <MDInput label="Name" fullWidth register={register("name", { required: true })} />
            <MDInput label="Email" fullWidth register={register("email", { required: true })} />
            <MDButton variant="gradient" color="success" onClick={onUpdateInfo}>
              Update
              {loading && <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />}
            </MDButton>
          </Stack>
        </MDBox>
        <MDBox mt={5} mb={3}>
          <Stack spacing={3}>
            <MDInput
              label="Old Password"
              type="password"
              fullWidth
              register={register("oldPassword", { required: true })}
            />
            <MDInput
              label="New Password"
              type="password"
              fullWidth
              register={register("password", { required: true })}
            />
            <MDInput
              label="Confirm New Password"
              type="password"
              fullWidth
              register={register("confirmPassword", { required: true })}
            />
            <MDButton variant="gradient" color="success" onClick={onUpdatePassword}>
              Update Password
              {loadingPassword && (
                <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />
              )}
            </MDButton>
          </Stack>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default Profile;
