/* eslint-disable */

import React, { useEffect, useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import { useNavigate, useParams } from "react-router-dom";

import { Box, Card, CircularProgress, Stack, Switch } from "@mui/material";
import { useMaterialUIController } from "context";

import pageApi from "api/page";
import domainApi from "api/domain";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { toast } from "react-toastify";

import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import MDBox from "components/MDBox";
import { blogRefExample, pageRefExample } from "utils/refSetting";

function PageInfo() {
  const navigate = useNavigate();
  const { domainName, pageId } = useParams();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [pageInfo, setPageInfo] = useState(null);
  const [pageRoute, setPageRoute] = useState(null);
  const [loading, setLoading] = useState(null);
  const [confirmGood, setConfirmGood] = useState(false);
  const [loadingDeleting, setLoadingDeleting] = useState(false);

  const getPageInfo = async () => {
    try {
      const res = await pageApi.getPageById(pageId);
      setPageRoute(res.page_route);
      setPageInfo(res.page_data);
    } catch (error) {
      navigate("/domain");
    }
  };

  const onChangeHandler = (e) => {
    if (e.error !== false) {
      setConfirmGood(true);
    } else {
      setConfirmGood(false);
      setPageInfo(e.jsObject);
    }
  };

  const updatePage = async () => {
    setLoading(true);
    try {
      const res = await pageApi.update(pageId, {
        page_route: pageRoute,
        page_data: { ...pageInfo },
      });
      toast.success(`Page updated successfully`);
    } catch (error) {
      toast.error(`${error.response.data.message || error.response.data.error}`);
    } finally {
      setLoading(false);
    }
  };

  const deletePage = async () => {
    setLoadingDeleting(true);
    try {
      const res = await pageApi.delete(pageId);
      toast.success(`Page deleted successfully`);
      navigate(`/domain/${domainName}/page`);
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    } finally {
      setLoadingDeleting(false);
    }
  };

  const navigateToSetting = () => navigate(`/domain/${domainName}/setting`);

  useEffect(() => {
    getPageInfo();
  }, []);

  return pageInfo !== null ? (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <MDButton color="info" onClick={navigateToSetting}>
          Go To Setting
        </MDButton>
        <MDButton color="error" onClick={() => deletePage()}>
          Delete
          {loadingDeleting && (
            <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />
          )}
        </MDButton>
      </MDBox>

      <Stack direction="row" width="100%" mb={4}>
        <Stack sx={{ flex: 1 }} direction="column">
          <MDTypography>Blog Ref Example</MDTypography>
          <JSONInput
            id={pageId}
            placeholder={blogRefExample}
            viewOnly={true}
            height="100%"
            width="99%"
          />
        </Stack>
        <Stack sx={{ flex: 1 }} direction="column">
          <MDTypography>Page Ref Example</MDTypography>
          <JSONInput
            id={pageId}
            placeholder={pageRefExample}
            viewOnly={true}
            height="100%"
            width="99%"
          />
        </Stack>
      </Stack>

      <Card sx={{ padding: 5, marginBottom: 10 }}>
        <MDInput
          placeholder="Page Route"
          fullWidth
          value={pageRoute}
          onChange={(e) => setPageRoute(e.target.value)}
          sx={{ marginBottom: 3 }}
        />

        <JSONInput
          id={pageId}
          placeholder={pageInfo}
          // colors={darktheme}
          locale={locale}
          height="100%"
          width="100%"
          onChange={(e) => onChangeHandler(e)}
        />

        <MDButton
          color="success"
          disabled={confirmGood}
          onClick={() => updatePage()}
          sx={{ marginTop: 5 }}
        >
          Update
          {loading && <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />}
        </MDButton>
      </Card>

      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default PageInfo;
