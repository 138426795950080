/* eslint-disable */

import React, { useEffect, useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import { useNavigate, useParams } from "react-router-dom";

import { Box, Card, CircularProgress, Stack } from "@mui/material";
import { useMaterialUIController } from "context";

import fileExtensionApi from "api/file-extension";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { toast } from "react-toastify";
import MDBox from "components/MDBox";
import _ from "lodash";
import ConfirmDialog from "components/ConfirmDialog";

function FileExtension() {
  const navigate = useNavigate();
  const { fileExtensionId } = useParams();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [fileExt, setFileExt] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const openDialogHandler = () => setOpenDialog(true);
  const closeDialogHandler = () => setOpenDialog(false);

  const getExtensionById = async () => {
    try {
      const res = await fileExtensionApi.getExtById({ id: fileExtensionId });
      setFileExt(res);
    } catch (error) {
      navigate("/");
    }
  };

  const onChangeHandler = (event) => {
    const newData = _.update(fileExt, event.target.name, (data) => {
      return event.target.value;
    });
    setFileExt({ ...newData });
  };

  const removeFromArray = (indexElement, isExt = false) => {
    setFileExt((current) => ({
      ...current,
      ext: isExt ? current.ext.filter((_, i) => i !== indexElement) : [...current.ext],
      htmlFile: !isExt
        ? current.htmlFile.filter((_, i) => i !== indexElement)
        : [...current.htmlFile],
    }));
  };

  const addToArray = (isExt = false) => {
    setFileExt((current) => ({
      ...current,
      ext: isExt ? [...current.ext, ""] : [...current.ext],
      htmlFile: !isExt ? [...current.htmlFile, ""] : [...current.htmlFile],
    }));
  };

  const updateFileExtension = async () => {
    setLoading(true);
    try {
      const res = await fileExtensionApi.updateExt({
        id: fileExtensionId,
        name: fileExt.name,
        ext: fileExt.ext,
        htmlFile: fileExt.htmlFile,
      });
      setFileExt(res);
      toast.success(`File Extension updated successfully`);
    } catch (error) {
      toast.error(`error: ${error.response.data.error}`);
    } finally {
      setLoading(false);
    }
  };

  const deleteFile = async () => {
    setLoadingDelete(true);
    try {
      const res = await fileExtensionApi.deleteExt({ id: fileExtensionId });
      toast.success(`Delete it successfully`);
      navigate("/file-extension");
    } catch (error) {
      toast.error(`error: ${error.response.data.error}`);
    } finally {
      setLoadingDelete(false);
    }
  };

  useEffect(() => {
    getExtensionById();
  }, []);

  return fileExt ? (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={3} mb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <MDTypography variant="h6" color="white">
              File Extension
            </MDTypography>
            <MDButton sx={{ width: 100 }} color="error" onClick={() => openDialogHandler()}>
              Delete
              {loadingDelete && (
                <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />
              )}
            </MDButton>
          </MDBox>
          <MDBox pt={3}>
            <Stack spacing={2} paddingX={5}>
              <MDInput
                value={fileExt.name}
                label={"Name"}
                name="name"
                fullWidth
                onChange={onChangeHandler}
              />
              <MDTypography variant="h6">Extension</MDTypography>
              <Stack spacing={2} paddingX={2}>
                {fileExt.ext.map((e, i) => (
                  <Stack direction="row" spacing={2} key={i}>
                    <MDInput
                      value={e}
                      label={`Ext.${i}`}
                      name={`ext.${i}`}
                      fullWidth
                      onChange={onChangeHandler}
                    />
                    <MDButton color="error" onClick={() => removeFromArray(i, true)}>
                      Remove
                    </MDButton>
                  </Stack>
                ))}
                <MDButton sx={{ width: 100 }} color="success" onClick={() => addToArray(true)}>
                  Add
                </MDButton>
              </Stack>
              <MDTypography variant="h6">HTML File</MDTypography>
              <Stack spacing={2} paddingX={2}>
                {fileExt.htmlFile.map((e, i) => (
                  <Stack direction="row" spacing={2} key={i}>
                    <MDInput
                      value={e}
                      label={`htmlFile.${i}`}
                      name={`htmlFile.${i}`}
                      fullWidth
                      onChange={onChangeHandler}
                    />
                    <MDButton color="error" onClick={() => removeFromArray(i)}>
                      Remove
                    </MDButton>
                  </Stack>
                ))}
                <MDButton sx={{ width: 100 }} color="success" onClick={() => addToArray()}>
                  Add
                </MDButton>
              </Stack>
            </Stack>
            <MDBox sx={{ marginX: 3 }}>
              <MDButton
                fullWidth
                sx={{ marginY: 3 }}
                color="info"
                onClick={() => updateFileExtension()}
              >
                Update
                {loading && (
                  <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />
                )}
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>

      <Footer />

      <ConfirmDialog
        open={openDialog}
        onClose={closeDialogHandler}
        onConfirm={deleteFile}
        title={"Delete Domain"}
        content={"Are you sure you want to delete domain"}
        confirmTitle="Delete"
      />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default FileExtension;
