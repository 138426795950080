/* eslint-disable */

import React, { useState, useRef } from "react";
import { CircularProgress, Stack } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

const MDFile = ({ title, value, domainId, path, onClickHandler }) => {
  const inputRef = useRef(null);

  const [chosenFile, setChosenFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const onChangeImage = (event) => {
    setChosenFile(event.target.files[0]);
  };

  const uploadFileHandler = () => inputRef.current.click();
  const onRemoveHandler = () => setChosenFile(null);
  const onUploadImage = async () => {
    setLoading(true);
    const imageResponse = await onClickHandler(domainId, path, chosenFile);
    if (imageResponse) onRemoveHandler();
    setLoading(false);
  };

  return (
    <Stack spacing={2} direction={chosenFile || value ? "column" : "row"}>
      {/* // <Stack spacing={2} direction={"column"}> */}
      <MDTypography>{title}</MDTypography>
      {chosenFile ? (
        <img src={URL.createObjectURL(chosenFile)} width={200} />
      ) : value ? (
        <img src={value} width={200} />
      ) : null}
      <input
        ref={inputRef}
        type="file"
        id="img"
        name="img"
        accept="image/*"
        onChange={onChangeImage}
        style={{ display: "none" }}
      />
      <Stack spacing={2} direction={"row"}>
        {!chosenFile && (
          <MDButton color="info" onClick={uploadFileHandler}>
            Update {title}
          </MDButton>
        )}
        {chosenFile && (
          <>
            <MDButton color="success" onClick={onUploadImage}>
              Upload Image{" "}
              {loading && <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />}
            </MDButton>
            <MDButton color="error" onClick={onRemoveHandler}>
              Remove Image
            </MDButton>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default MDFile;
