module.exports = {
  name: {
    type: "string",
    name: "name",
    required: true,
    placeHolder: "123",
    defaultValue: "شسي",
    multiLine: false,
  },
  email: {
    type: "string",
    validate: "email",
    name: "email",
    required: true,
    placeHolder: "me@omars.dev",
    defaultValue: "12",
    multiLine: false,
  },
  phoneNumber: {
    type: "string",
    validate: "phoneNumber",
    defaultCountry: "JO",
    required: true,
  },
  message: {
    type: "string",
    length: 2,
    required: true,
  },
  gender: {
    type: "string",
    required: true,
    valid: ["Male", "Female"],
  },
  number: {
    type: "number",
    min: 1,
    max: 10,
    defaultValue: 12,
  },
  categories: {
    type: "array",
    itemType: "string",
    valid: ["T-Shirt", "Short"],
    required: true,
    itemRequired: true,
  },
  skills: {
    type: "array",
    itemType: "string",
    itemRequired: true,
    required: false,
  },
  sizes: {
    type: "array",
    itemType: "number",
    itemRequired: true,
    required: true,
    min: 1,
    itemMin: 1,
    max: 12,
    itemMax: 12,
  },
};
