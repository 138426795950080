import AxiosInstance from "./AxiosInstance";

const page = {
  getAllPages: async (domainId) => {
    const res = await AxiosInstance.get(`/page/employee/${domainId}`);
    return res.data;
  },

  getPageById: async (pageId) => {
    const res = await AxiosInstance.get(`/page/employee/one/${pageId}`);
    return res.data;
  },

  create: async (body) => {
    const res = await AxiosInstance.post(`/page`, body);
    return res.data;
  },

  update: async (pageId, body) => {
    const res = await AxiosInstance.put(`/page/employee/${pageId}`, body);
    return res.data;
  },

  delete: async (pageId) => {
    const res = await AxiosInstance.delete(`/page/employee/${pageId}`);
    return res.data;
  },
};

export default page;
