import AxiosInstance from "./AxiosInstance";

const subscription = {
  getAll: async () => {
    const res = await AxiosInstance.get(`/subscription`);
    return res.data;
  },
  getById: async (subscriptionId) => {
    const res = await AxiosInstance.get(`/subscription/${subscriptionId}`);
    return res.data;
  },
  getByDomainName: async (domainName) => {
    const res = await AxiosInstance.get(`/subscription/domain?domainName=${domainName}`);
    return res.data;
  },
  create: async (body) => {
    const res = await AxiosInstance.post(`/subscription`, body);
    return res.data;
  },
  update: async (subscriptionId, body) => {
    const res = await AxiosInstance.put(`/subscription/${subscriptionId}`, body);
    return res.data;
  },
  delete: async (subscriptionId) => {
    const res = await AxiosInstance.delete(`/subscription/${subscriptionId}`);
    return res.data;
  },
};

export default subscription;
