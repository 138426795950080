/* eslint-disable */

import React, { useEffect, useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import { Link, useNavigate, useParams } from "react-router-dom";

import { Box, Card, CircularProgress, Stack, Switch } from "@mui/material";
import { useMaterialUIController } from "context";

import domainApi from "api/domain";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { toast } from "react-toastify";
import ConfirmDialog from "components/ConfirmDialog";

const allowedItem = [
  "domain",
  "subDomains",
  "isBlogActivate",
  "isMETAActivate",
  "isDeveloper",
  "limitStorage",
  "usedStorage",
];

function DomainInfo() {
  const navigate = useNavigate();
  const { domainName } = useParams();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [domain, setDomain] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const openDialogHandler = () => setOpenDialog(true);
  const closeDialogHandler = () => setOpenDialog(false);

  const getDomain = async () => {
    try {
      await domainApi.getDomainInfoByDomainName(domainName, setDomain);
    } catch (error) {
      navigate("/domain");
    }
  };

  const deleteDomain = async () => {
    setLoading(true);
    try {
      const res = await domainApi.deleteDomain(domain._id);
      toast.success("Domain Delete it successfully");
      navigate("/domain");
    } catch (error) {
      toast.error(`Error: While Deleting ${error.response.data.success}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDomain();
  }, []);

  return domain ? (
    <DashboardLayout>
      <DashboardNavbar />

      <Card sx={{ padding: 5 }}>
        <Stack spacing={1}>
          {allowedItem.map((e, i) => (
            <MDTypography sx={{ fontSize: 15 }} key={i}>
              {e}: {domain?.[e]?.toString()}
            </MDTypography>
          ))}
          <Stack direction="row" spacing={5}>
            <Link to={`page`}>
              <MDButton color="success">Pages</MDButton>
            </Link>
            <Link to={`setting`}>
              <MDButton color="success">Setting</MDButton>
            </Link>
            <Link to={`update`}>
              <MDButton color="success">Update Domain</MDButton>
            </Link>
            <Link to={`subscription`}>
              <MDButton color="success">Domain Subscription</MDButton>
            </Link>
            <Link to={`/owner/${domain.ownerId}`}>
              <MDButton color="success">Go To owner</MDButton>
            </Link>
            <MDButton color="error" onClick={openDialogHandler}>
              Delete Domain
              {loading && <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />}
            </MDButton>
          </Stack>
        </Stack>
      </Card>
      <Footer />
      <ConfirmDialog
        open={openDialog}
        onClose={closeDialogHandler}
        onConfirm={deleteDomain}
        title={"Delete Domain"}
        content={"Are you sure you want to delete domain"}
        confirmTitle="Delete"
      />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default DomainInfo;
