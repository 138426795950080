import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";

function MDDialog({ open, onClose, onConfirm, title, confirmTitle, children }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const onConfirmHandler = () => {
    onClose();
    onConfirm();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ style: { backgroundColor: darkMode && "#202940" } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <MDButton onClick={onClose} color="error" size="small">
          Cancel
        </MDButton>
        <MDButton onClick={onConfirmHandler} color="success" size="small">
          {confirmTitle}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

MDDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
export default MDDialog;
