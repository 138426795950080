import AxiosInstance from "./AxiosInstance";

const notification = {
  sendNotificationToClient: async (ownerId, body) => {
    const res = await AxiosInstance.post(`notification/send/owner/${ownerId}`, body);
    return res.data;
  },
};

export default notification;
