/* eslint-disable */

import React, { useEffect, useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import { useNavigate, useParams } from "react-router-dom";

import { Box, Card, CircularProgress } from "@mui/material";
import { useMaterialUIController } from "context";

import pageApi from "api/page";
import domainApi from "api/domain";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";

const COLUMNS = [
  { Header: "Route", accessor: "name", align: "left" },
  { Header: "Created At", accessor: "createdAt", align: "center" },
  { Header: "Updated At", accessor: "updatedAt", align: "center" },
  { Header: "View", accessor: "view", align: "left" },
];

function Page() {
  const navigate = useNavigate();
  const { domainName } = useParams();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [domainInfo, setDomainInfo] = useState(null);
  const [domainPages, setDomainPages] = useState(null);

  const getDomainPages = async () => {
    try {
      const res = await pageApi.getAllPages(domainInfo._id);

      const rows = [];
      res.forEach((page) => {
        rows.push({
          name: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {page.page_route}
            </MDTypography>
          ),
          createdAt: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {new Date(page.createdAt).toLocaleDateString()}
            </MDTypography>
          ),
          updatedAt: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {new Date(page.updatedAt).toLocaleDateString()}
            </MDTypography>
          ),
          view: (
            <MDButton
              color="success"
              size="small"
              onClick={() => navigate(`/domain/${domainName}/page/${page._id}`)}
            >
              Edit
            </MDButton>
          ),
        });
      });

      setDomainPages(rows);
    } catch (error) {
      navigate("/domain");
    }
  };

  const navigateToCreatePage = () => navigate(`/domain/${domainName}/page/create`);

  useEffect(() => {
    domainApi.getDomainInfoByDomainName(domainName, (res) => setDomainInfo(res));
  }, []);

  useEffect(() => {
    if (!domainInfo || domainPages) return;
    getDomainPages();
  }, [domainInfo]);

  return domainPages ? (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={3} mb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <MDTypography variant="h6" color="white">
              Pages
            </MDTypography>
            <MDButton color="success" size="small" onClick={() => navigateToCreatePage()}>
              Create Page
            </MDButton>
          </MDBox>
          <MDBox pt={3}>
            <DataTable
              table={{ columns: COLUMNS, rows: domainPages }}
              isSorted={true}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
        </Card>
      </MDBox>

      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default Page;
