import AxiosInstance from "./AxiosInstance";

const fileExtension = {
  getAllExt: async () => {
    const res = await AxiosInstance.get("file-extension");
    return res.data;
  },
  getExtById: async ({ id }) => {
    const res = await AxiosInstance.get(`file-extension/${id}`);
    return res.data;
  },
  createExt: async ({ name, ext, htmlFile }) => {
    const res = await AxiosInstance.post("file-extension", {
      name,
      ext,
      htmlFile,
    });
    return res.data;
  },
  updateExt: async ({ id, name, ext, htmlFile }) => {
    const res = await AxiosInstance.put(`file-extension/${id}`, {
      name,
      ext,
      htmlFile,
    });
    return res.data;
  },
  deleteExt: async ({ id }) => {
    const res = await AxiosInstance.delete(`file-extension/${id}`);
    return res.data;
  },
};

export default fileExtension;
