/* eslint-disable */

import React, { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Dashboard components
import { useNavigate, useParams } from "react-router-dom";
import { useOwnerStore, useDomainStore } from "store";

import { Box, Card, CircularProgress } from "@mui/material";
import { useMaterialUIController } from "context";

import ownerApi from "api/owner";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

function Owner() {
  const { ownerId } = useParams();
  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [owner, setOwner] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const onOpenEditMode = () => setIsEditMode((old) => !old);

  const getOwner = async () => {
    try {
      const res = await ownerApi.getClientById(ownerId);
      setOwner(res);
    } catch (error) {}
  };

  useEffect(() => {
    getOwner();
  }, []);

  return owner ? (
    <DashboardLayout>
      <DashboardNavbar />

      <ProfileInfoCard
        title="Owner information"
        description=""
        info={{
          name: owner.name,
          email: owner.email,
          phone: owner.phone,
        }}
        action={{ tooltip: "Profile", onclick: onOpenEditMode, getOwner: getOwner }}
        shadow={false}
        isEditMode={isEditMode}
      />

      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default Owner;
