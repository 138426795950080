import AxiosInstance from "./AxiosInstance";

const auth = {
  loginEmployee: async (data) => {
    const res = await AxiosInstance.post("auth/employee", data);
    return res.data;
  },
};

export default auth;
