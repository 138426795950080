/* eslint-disable */
import create from "zustand";
import { persist } from "zustand/middleware";

const ownerStore = (set) => ({
  ownerStore: null,
  ownerData: null,
  setOwnerStore: (ownerStoreParams) => set(() => ({ ownerStore: ownerStoreParams })),
  setOwnerData: (ownerDataParams) => set(() => ({ ownerData: ownerDataParams })),
  resetData: () => set(() => ({ ownerData: null, ownerStore: null })),
  _hasHydrated: false,
  setHasHydrated: (state) => set(() => ({ _hasHydrated: state })),
});

const ownerStorePersist = persist(ownerStore, {
  name: "ownerToken",
  onRehydrateStorage: () => (state) => {
    state.setHasHydrated(true);
  },
  partialize: (state) => ({ ownerStore: state.ownerStore }),
});

export const useOwnerStore = create(ownerStorePersist);
