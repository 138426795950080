import AxiosInstance from "./AxiosInstance";

const owner = {
  getAllClients: async () => {
    const res = await AxiosInstance.get("owner");
    return res.data;
  },
  getClientById: async (id) => {
    const res = await AxiosInstance.get(`owner/${id}`);
    return res.data;
  },
  getClientTokenById: async (id) => {
    const res = await AxiosInstance.get(`owner/token/${id}`);
    return res.data;
  },
  createClient: async (data) => {
    const res = await AxiosInstance.post(`owner`, data);
    return res.data;
  },
  updateClientInfo: async (id, data) => {
    const res = await AxiosInstance.put(`owner/update-employee/${id}`, data);
    return res.data;
  },
  updateClientPassword: async (id) => {
    const res = await AxiosInstance.put(`owner/update-password-employee/${id}`);
    return res.data;
  },
  updateClientPasswordManual: async (id, body) => {
    const res = await AxiosInstance.put(`owner/update-password-employee/manual/${id}`, body);
    return res.data;
  },
  delete: async (id) => {
    const res = await AxiosInstance.delete(`owner/${id}`);
    return res.data;
  },
};

export default owner;
