/* eslint-disable */

import React, { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Dashboard components
import { useNavigate } from "react-router-dom";
import { useOwnerStore, useDomainStore } from "store";

import { Box, CircularProgress } from "@mui/material";
import { useMaterialUIController } from "context";

function Dashboard() {
  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [ready, setReady] = useState(true);

  // useEffect(() => {
  //   if (!domainStore) return;
  //   const newMessageAnalysis = {
  //     datasets: {
  //       data: [],
  //       label: "messages",
  //     },
  //     labels: [],
  //   };
  //   const newPagesAnalysis = {
  //     datasets: {
  //       data: [],
  //       label: "pages",
  //     },
  //     labels: [],
  //   };
  //   domainStore.messageAnalysis.forEach((msg) => {
  //     newMessageAnalysis.datasets.data.push(msg.count);
  //     newMessageAnalysis.labels.push(msg._id);
  //   });
  //   domainStore.pageAnalysis.forEach((day) => {
  //     newPagesAnalysis.datasets.data.push(day.count);
  //     newPagesAnalysis.labels.push(day._id);
  //   });
  //   setPagesAnalysis(newPagesAnalysis);
  //   setMessagesAnalysis(newMessageAnalysis);
  //   setReady(true);
  // }, [domainStore]);

  return ready ? (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="language_icon"
                title="Total Domains"
                count={domainStore.domain}
                percentage={{
                  color: "success",
                  amount: "1",
                  label: "Domain only",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Total Visit"
                count={domainStore.views}
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Total Pages"
                count={domainStore.page}
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Total Messages"
                count={domainStore.message}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="info"
                  title="Daily Visits"
                  date="updated just now"
                  chart={pagesAnalysis}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="dark"
                  title="Total Messages"
                  date="just updated"
                  chart={messagesAnalysis}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox> */}
      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default Dashboard;
