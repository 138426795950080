import AxiosInstance from "./AxiosInstance";

const employee = {
  getEmployeeByToken: async () => {
    const res = await AxiosInstance.get("employee/me");
    return res.data;
  },
  update: async (employeeId, body) => {
    const res = await AxiosInstance.put(`employee/${employeeId}`, body);
    return res.data;
  },
  updatePassword: async (body) => {
    const res = await AxiosInstance.put("employee/change-password", body);
    return res.data;
  },
};

export default employee;
