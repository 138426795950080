/* eslint-disable */

import React, { useEffect, useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import { useNavigate, useParams } from "react-router-dom";

import { Box, Card, CircularProgress, Stack, Switch } from "@mui/material";
import { useMaterialUIController } from "context";

import settingApi from "api/setting";
import domainApi from "api/domain";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { toast } from "react-toastify";

import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import MDBox from "components/MDBox";
import { blogRefExample, pageRefExample } from "utils/refSetting";
import formsSetting from "utils/formsSetting";

function Setting() {
  const navigate = useNavigate();
  const { domainName } = useParams();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [domainInfo, setDomainInfo] = useState(null);
  const [settingData, setSettingData] = useState(null);
  const [customPages, setCustomPages] = useState(null);
  const [formsData, setFormsData] = useState(null);
  const [settingId, setSettingId] = useState(null);
  const [loading, setLoading] = useState(null);
  const [confirmGood, setConfirmGood] = useState(false);

  const getSetting = async () => {
    try {
      const res = await settingApi.getByDomainId(domainInfo._id);
      setSettingId(res._id);
      setSettingData(res.setting);
      setCustomPages(res.customPages.map((e) => ({ route: e.route, data: e.data })));
      setFormsData(res.formsData.map((e) => ({ key: e.key, data: e.data })) || []);
    } catch (error) {
      navigate("/domain");
    }
  };

  const onChangeSetting = (e) => {
    if (e.error !== false) {
      setConfirmGood(true);
    } else {
      setConfirmGood(false);
      setSettingData(e.jsObject);
    }
  };

  const updateSetting = async () => {
    setLoading(true);
    try {
      const res = await settingApi.update(settingId, {
        setting: settingData,
        customPages,
        formsData,
      });
      toast.success(`Setting updated successfully`);
    } catch (error) {
      toast.error(`${error.response.data.message || error.response.data.error}`);
    } finally {
      setLoading(false);
    }
  };

  const onUpdateOwnerPages = (e, index) => {
    if (e.jsObject) {
      const oldOwnerData = [...customPages];
      oldOwnerData[index].data = e.jsObject;
      setCustomPages(oldOwnerData);
    }
  };
  const onUpdateOwnerPagesRoute = (e, index) => {
    const oldOwnerData = [...customPages];
    oldOwnerData[index].route = e.target.value;
    setCustomPages(oldOwnerData);
  };
  const onRemoveItem = (index) => {
    const oldOwnerData = customPages.filter((_, i) => i !== index);
    setCustomPages(oldOwnerData);
  };

  const onUpdateFormsData = (e, index) => {
    if (e.jsObject) {
      const oldOwnerData = [...formsData];
      oldOwnerData[index].data = e.jsObject;
      setFormsData(oldOwnerData);
    }
  };
  const onUpdateFormsKey = (e, index) => {
    const oldOwnerData = [...formsData];
    oldOwnerData[index].key = e.target.value;
    setFormsData(oldOwnerData);
  };
  const onRemoveForms = (index) => {
    const oldOwnerData = formsData.filter((_, i) => i !== index);
    setFormsData(oldOwnerData);
  };

  useEffect(() => {
    domainApi.getDomainInfoByDomainName(domainName, (res) => setDomainInfo(res));
  }, []);

  useEffect(() => {
    if (!domainInfo || customPages) return;
    getSetting();
  }, [domainInfo]);

  return settingData !== null && customPages !== null && formsData !== null ? (
    <DashboardLayout>
      <DashboardNavbar />

      <MDTypography fontSize={25}>{domainName.toUpperCase()} Setting</MDTypography>

      <Card sx={{ marginBottom: 10, marginTop: 5 }}>
        <Card sx={{ padding: 5, marginBottom: 5 }}>
          <JSONInput
            id={settingId}
            placeholder={settingData}
            // colors={darktheme}
            locale={locale}
            height="100%"
            width="100%"
            onChange={(e) => onChangeSetting(e)}
          />
        </Card>

        <Card
          sx={{
            padding: 2,
            marginBottom: 5,
            flexDirection: "row-reverse",
            justifyContent: "space-around",
          }}
        >
          <Stack fdirection="row" width="100%" mb={4} flex={1}>
            <Stack sx={{ flex: 1 }} direction="column">
              <MDTypography>Blog Ref Example</MDTypography>
              <JSONInput placeholder={blogRefExample} viewOnly={true} height="100%" width="99%" />
            </Stack>
            <Stack sx={{ flex: 1 }} direction="column">
              <MDTypography>Page Ref Example</MDTypography>
              <JSONInput placeholder={pageRefExample} viewOnly={true} height="100%" width="99%" />
            </Stack>
          </Stack>

          <MDBox sx={{ flex: 2 }}>
            <MDTypography fontWeight="medium">Owner Custom Page Setting</MDTypography>
            {customPages.map((e, index) => (
              <Card sx={{ padding: 2, marginBottom: 5 }} key={index}>
                <MDInput
                  style={{ marginBottom: 20 }}
                  value={e.route}
                  onChange={(e) => onUpdateOwnerPagesRoute(e, index)}
                />
                <JSONInput
                  id={index}
                  placeholder={e.data}
                  locale={locale}
                  height="100%"
                  width="100%"
                  onChange={(e) => onUpdateOwnerPages(e, index)}
                  style={{ marginTop: 2 }}
                />
                <MDButton
                  color="error"
                  onClick={() => onRemoveItem(index)}
                  sx={{ marginTop: 1, width: 100 }}
                >
                  Remove
                </MDButton>
              </Card>
            ))}
            <MDButton
              color="success"
              disabled={confirmGood}
              onClick={() => setCustomPages((old) => [...old, { route: "", data: {} }])}
              sx={{ marginTop: 3 }}
            >
              Add New Custom Page
            </MDButton>
          </MDBox>
        </Card>

        <Card
          sx={{
            padding: 2,
            marginBottom: 5,
            flexDirection: "row-reverse",
            justifyContent: "space-around",
          }}
        >
          <Stack sx={{ flex: 1 }} direction="column">
            <MDTypography>Form Example</MDTypography>
            <JSONInput placeholder={formsSetting} viewOnly={true} height="100%" width="99%" />
          </Stack>

          <MDBox sx={{ flex: 2 }}>
            <MDTypography fontWeight="medium">Forms Setting</MDTypography>
            {formsData.map((e, index) => (
              <Card sx={{ padding: 2, marginBottom: 5 }} key={index}>
                <MDInput
                  style={{ marginBottom: 20 }}
                  value={e.key}
                  onChange={(e) => onUpdateFormsKey(e, index)}
                />
                <JSONInput
                  id={index}
                  placeholder={e.data}
                  locale={locale}
                  height="100%"
                  width="100%"
                  onChange={(e) => onUpdateFormsData(e, index)}
                  style={{ marginTop: 2 }}
                />
                <MDButton
                  color="error"
                  onClick={() => onRemoveForms(index)}
                  sx={{ marginTop: 1, width: 100 }}
                >
                  Remove
                </MDButton>
              </Card>
            ))}
            <MDButton
              color="success"
              disabled={confirmGood}
              onClick={() => setFormsData((old) => [...old, { key: "", data: {} }])}
              sx={{ marginTop: 3 }}
            >
              Add New Custom Forms
            </MDButton>
          </MDBox>
        </Card>

        <MDButton
          color="success"
          disabled={confirmGood}
          onClick={() => updateSetting()}
          sx={{ marginTop: 5 }}
        >
          Update
          {loading && <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />}
        </MDButton>
      </Card>

      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default Setting;
