import AxiosInstance from "./AxiosInstance";

const auth = {
  updateDomainMeta: async (domainId, path, data) => {
    const res = await AxiosInstance.post(`media/meta/domain/${domainId}?path=${path}`, data);
    return res.data;
  },
};

export default auth;
