/* eslint-disable */

import React, { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Dashboard components
import { useNavigate } from "react-router-dom";
import { useOwnerStore, useDomainStore } from "store";

import { Box, Card, CircularProgress } from "@mui/material";
import { useMaterialUIController } from "context";

import ownerApi from "api/owner";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";

const COLUMNS = [
  { Header: "Name", accessor: "name", align: "left" },
  { Header: "Email", accessor: "email", align: "center" },
  { Header: "Phone Number", accessor: "phone", align: "center" },
  { Header: "Created At", accessor: "createdAt", align: "center" },
  { Header: "Updated At", accessor: "updatedAt", align: "center" },
  { Header: "Create Domain", accessor: "createDomain", align: "center" },
  { Header: "Login", accessor: "login", align: "center" },
  { Header: "View", accessor: "view", align: "center" },
];

function Owners() {
  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [owners, setOwners] = useState(null);
  const [ownersRow, setOwnersRow] = useState(null);

  const navigateToOwner = (id) => navigate(`/owner/${id}`);
  const navigateToCreateOwner = (id) => navigate(`/owner/create`);

  const getOwnerToken = async (id) => {
    const { token } = await ownerApi.getClientTokenById(id);
    const domain =
      process.env.NODE_ENV === "development"
        ? "http://localhost:3334"
        : process.env.NODE_ENV === "qa"
        ? "https://client-qa.orkabit.com"
        : "https://client.orkabit.com";

    window.open(`${domain}/dashboard/?token=${token}`, "_blank");
  };

  const getAllClients = async () => {
    try {
      const res = await ownerApi.getAllClients();

      const rows = [];
      res.forEach((owner) => {
        rows.push({
          name: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {owner.name}
            </MDTypography>
          ),
          email: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {owner.email}
            </MDTypography>
          ),
          phone: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {owner.phone}
            </MDTypography>
          ),
          createdAt: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {new Date(owner.createdAt).toLocaleDateString()}
            </MDTypography>
          ),
          updatedAt: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {new Date(owner.updatedAt).toLocaleDateString()}
            </MDTypography>
          ),
          createDomain: (
            <MDButton
              color="secondary"
              size="small"
              onClick={() => navigate(`/domain/create/${owner._id}`)}
            >
              create domain
            </MDButton>
          ),
          login: (
            <MDButton color="success" size="small" onClick={() => getOwnerToken(owner._id)}>
              Login
            </MDButton>
          ),
          view: (
            <MDButton color="info" size="small" onClick={() => navigateToOwner(owner._id)}>
              view
            </MDButton>
          ),
        });
      });

      setOwnersRow(rows);
      setOwners(res);
    } catch (error) {}
  };

  useEffect(() => {
    getAllClients();
  }, []);

  return owners ? (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={3} mb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <MDTypography variant="h6" color="white">
              Owners
            </MDTypography>
            <MDButton color="success" size="small" onClick={() => navigateToCreateOwner()}>
              Create Owner
            </MDButton>
          </MDBox>
          <MDBox pt={3}>
            <DataTable
              table={{ columns: COLUMNS, rows: ownersRow }}
              isSorted={true}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
        </Card>
      </MDBox>

      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default Owners;
