/* eslint-disable */

import React, { useEffect, useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import { Link, useNavigate, useParams } from "react-router-dom";

import { Box, Card, CircularProgress, Stack, Switch } from "@mui/material";
import { useMaterialUIController } from "context";

import domainApi from "api/domain";
import subscriptionApi from "api/subscription";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";

import moment from "moment";
import MDInput from "components/MDInput";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import handleError from "utils/handleError";

function CreateDomainSubscription() {
  const navigate = useNavigate();
  const { domainName } = useParams();

  const { register, getValues, setValue, watch } = useForm();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [domain, setDomain] = useState(null);
  const [endSubscription, setEndSubscription] = useState(null);
  const [loading, setLoading] = useState(null);

  const handleChange = (newValue) => {
    setEndSubscription(newValue);
  };

  const getDomain = async () => {
    try {
      await domainApi.getDomainInfoByDomainName(domainName, setDomain);
    } catch (error) {
      navigate("/domain");
    }
  };

  const onCreateSubscription = async () => {
    setLoading(true);
    const values = getValues();

    const body = {
      ...values,
      endSubscription: endSubscription.format("DD-MM-YYYY"),
      domainId: domain._id,
    };
    try {
      const res = await subscriptionApi.create(body);
      toast.success(`Subscription Created Successfully`);
    } catch (error) {
      toast.error(handleError(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDomain();
  }, []);

  return domain ? (
    <DashboardLayout>
      <DashboardNavbar />

      <Card sx={{ padding: 5, marginBottom: 10 }}>
        <Stack spacing={4}>
          <MDInput
            label="Description"
            fullWidth
            register={register("description", { required: true })}
          />
          <MDInput label="Amount" fullWidth register={register("amount", { required: true })} />
          <MDInput label="Currency" fullWidth register={register("currency", { required: true })} />
          <MDInput
            label="Payment Type"
            fullWidth
            register={register("paymentType", { required: true })}
          />
          <DesktopDatePicker
            label="End Subscription"
            inputFormat="MM/DD/YYYY"
            value={endSubscription}
            onChange={handleChange}
            renderInput={(params) => <MDInput {...params} />}
          />
          <MDButton fullWidth color="success" onClick={() => onCreateSubscription()}>
            Create
            {loading && <CircularProgress color="inherit" size="0.8rem" sx={{ marginLeft: 1 }} />}
          </MDButton>
        </Stack>
      </Card>
      <Footer />
    </DashboardLayout>
  ) : (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color={!darkMode ? "inherit" : "white"} />
    </Box>
  );
}

export default CreateDomainSubscription;
