import AxiosInstance from "./AxiosInstance";

const domain = {
  getAllDomains: async () => {
    const res = await AxiosInstance.get("/domain");
    return res.data;
  },

  getDomainInfo: async (id) => {
    const res = await AxiosInstance.get(`/domain/${id}`);
    return res.data;
  },

  getDomainInfoByDomainName: async (domainName, setDomainInfo) => {
    const res = await AxiosInstance.get(`/domain/name?domainName=${domainName}`);
    setDomainInfo(res.data);
    return res.data;
  },

  getAllDomainsByOwner: async (ownerId) => {
    const res = await AxiosInstance.get(`/domain/owner/${ownerId}`);
    return res.data;
  },

  createDomain: async (ownerId, body) => {
    const res = await AxiosInstance.post(`/domain`, {
      ...body,
      ownerId,
    });
    return res.data;
  },
  updateDomain: async (id, body) => {
    const res = await AxiosInstance.put(`/domain/${id}`, body);
    return res.data;
  },
  deleteDomain: async (id) => {
    const res = await AxiosInstance.delete(`/domain/${id}`);
    return res.data;
  },
};

export default domain;
